.loanTable {
  margin: 20px;
  width: 100%;

  .expandAllButton {
    color: #cc0000;
    margin-left: 0.5em;
    text-transform: none;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.7777em;
  }

  .tableTitle {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0.5em;
    display: flex;
    font-size: 1.125em;
    align-items: baseline;

  }
  table.mainTable {
    width: calc(100% - 40px);
    border-collapse: collapse;
    table-layout: fixed;
    + table {
      margin-top: 1em;
    }
    td {
      padding: 0.3em;
      padding-left: 1em;

      &:last-child {
        text-align: right;
      }
    }
    .widthRow {
      td {
        padding: 0;
      }
    }

    .alignRight {
      text-align: right;
    }

    .loanRow {
      font-size: 0.875em;
    }
  }

  .chevron {
    transition: transform 0.3s ease-in-out;
    margin-right: 0.2em;
    cursor: pointer;

    &.white {
      margin-right: 1em;
      filter: invert(100%);
    }
    &.expanded {
      transform: rotate(90deg);
    }
  }

}

.filters {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  background-color: #FBFBFA;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  padding: 10px 20px;
  width: 100%;
}

.myTooltip {
  max-width: 30em;
  text-align: center;
}