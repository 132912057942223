.tableControls {
  display: flex;
  font-size: 0.875em;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  background-color: #FBFBFA;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
  justify-content: space-between;
  .backToIntro {
    padding: 0.9em 1em 0.9em 2.5em;
    text-transform: uppercase;
    cursor: pointer;
    border-right: 1px solid #cccccc;
    font-weight: bold;
    color: #000;
    background: transparent url('../images/backArrow.svg') no-repeat left 20px center;
  }
  .tableButtons {
    padding: 0.5em 20px;
    display: flex;
  }
  .toggleLabel {
    color: #000;
    font-weight: 600;
  }
  .toggle {
    display: flex;
    align-items: center;
    + .toggle {
      margin-left: 2em;
    }
    .buttons {
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 1em;
      display: inline-block;
      margin-left: 1em;
    }
    button {
      appearance: none;
      border: 0;
      background-color: transparent;
      font-size: 1em;
      padding: 0.2em 0.75em;
      cursor: pointer;
      color: rgba(0,0,0, 0.5);
      border-radius: 1em;
      text-transform: uppercase;
      font-weight: 600;
      &.active {
        color: #fff;
        background: #cc0000;
        border: 1px solid #cc0000;
        transform: scale(1.1);
      }
    }
  }
}
