.AboutModal {

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 98;
  padding-top: 4em;

  .content {
    max-width: 40em;
    margin: 0 auto;
    position: relative;
    .copy {
      padding-right: 2em;
      max-height: calc(100vh - 8em);
      overflow-y: auto;
    }
  }

  .close {
    position: absolute;
    top: 0 em;
    right: -2em;
    font-size: 3em;
    cursor: pointer;
  }

  p + p {
    margin-top: 1em;
  }
  .aboutHead {
    margin: 1em 0;
  }

  a {
    color: #cc0000;
    text-decoration: underline;
  }
}
