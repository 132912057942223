.sizeContainer {
  margin: 0 auto;
  left: 0;
  position: relative;
  &.inIntro {

    position: fixed;
    top: 0;
  }

  .mapContainer {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: move;

    .transformContainer {
      will-change: transform;
      -webkit-transform: translateZ(0);
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      transform-origin: 0 0 0;
    }

    .transformContainer {
      transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    }

    &.transition .stack path,
    &.transition path.shadow {
      opacity: 0;
    }
  }

  /* zoom */
  .controls {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 20;

    .rippleButton {
      height: 26px;
      margin-bottom: 4px;
    }

    button {
      margin: 0 !important;
      padding: 0 !important;
      padding-bottom: 2px !important;
      width: 26px !important;
      height: 26px;
      border: 1px solid #666666;
      border-radius: 4px;
      line-height: 22px;
      font-size: 12pt;
      font-weight: bold;
      text-align: center;
      background: white;
      color: black;
      cursor: pointer;
      outline: none;
      img {
        width: 20px;
        margin: 2px 2.5px;
      }
    }

    button.inactive {
      background: #c8c8c6 !important;
    }
  }

  .colorScale {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    padding-top: calc(1em - 3px);
    background-color: rgba(203, 242, 255, 0.5);
    z-index: 20;
    border-bottom-left-radius: 0.5em;

    .tick {
      display: inline-block;
      // font-size: 0.7rem;
      font-size: 0.7em;
      text-align: center;;
      .tickFill {
        width: 45px;
        height: 15px;
      }
      .tickLabel {
        margin-top: 2px;
      }
    }
  }

  .countryColorScale {
    position: absolute;
    bottom: 3em;
    left: 20px;
    z-index: 10;
    font-size: 0.875em;
    padding: 0.5em 2em 0.5em 1em;
    background-color: rgba(255, 255, 255, 0.6);
    svg {
      overflow: visible;
    }
  }

  .graphTitles {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 95;
    font-size: 1.4em;
    .year {
      color: #cc0000;
      font-size: 0.875em;
    }
    .countryLabel {
      text-transform: uppercase;
      font-size:1.125em;
      font-weight: bold;
    }
  }
}

.Map {
  overflow: visible;

  .background {
    fill: #CCCCCC;
  }

  .country, .region {
    will-change: fill;
    transition: fill 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .country.clickable:hover {
    fill: #eee;
  }
  path.shadow {
    // transition: opacity 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .stack path {
    cursor: pointer;
    // transition: opacity 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }

  g.stack, g.shadow {
    transition: transform 0.3s ease-in-out;
  }

  .stack path:last-child {
    pointer-events: none;
  }
}

@media screen and (max-width: 812px) {
  .sizeContainer .colorScale {
    font-size: 0.75em;
    padding: 0.5em;
  }
}

@media screen and (max-width: 414px) {
  .sizeContainer {
    .controls {
      top: 0.5em;
      left: 0.5em;

      .rippleButton {
        height: 19px;
        margin-bottom: 2px;

        button {
          width: 19px !important;;
          height: 19px;
          line-height: 14px;
          padding: 0 !important;

          img {
            width: 14px;
          }
        }
      }
    }

    .colorScale {
      padding-top: 0.75em;
      padding-right: 0.75em;

      .tick .tickFill {
        width: 35px;
      }
    }
  }



}
