header.header {
  display: flex;
  background-color: #000;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  .header__logo {
    width: 313px;
    height: 32px;
  }
  h1 {
    font-weight: 600;
    font-size: 1.5em;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .menu {
    display: flex;
    font-size: 0.85rem;

    button {
      font-weight: 600;
      font-size: 0.85rem;
      appearance: none;
      cursor: pointer;
      color: #fff;
      background: none;
      border: 0;
      margin-right: 16px;
    }

    img {
      margin-left: 1rem;
      width: 1rem;
      height: 1rem;
    }
  }

  .languageSelector {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
    line-height: 2em;

    .group {
      display: flex;
    }

    .language {
      margin-left: 1em;
      cursor: pointer;

      &.selected {
        border-bottom: 2px solid white;
      }
    }
  }

  .links {
    display: flex;
    align-items: center;
  }

  .showPreview & {
    .links {
      display: none;
    }
  }
}
