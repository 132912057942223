.TypeFilterGraph {
  position: relative;
  display: inline-block;
  .reset {
    position: absolute;
    top: 0;
    right: 0;

  }

  .graphContainer {
    position: relative;

    .typeFilterGraphTooltip{
      
    }
  }

  rect {
    transition: 0.3s ease-in-out opacity;
  }

  .lenderExplainer {
    position: absolute;
    top: 24px;
    left: 60px;
    background-color: #fff;
    border-radius: 0.5em;
    font-weight: 600;
    font-size: 0.85em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    z-index: 100;
    table {
      border-radius: 0.5em;
      border-collapse: collapse;
      td {
        padding: 0.2em 0.5em;
        max-width: 30em;
      }
      thead {
        td {
          background-color: #707070;
          color: #fff;
          vertical-align: top;
          padding: 0.5em;
          &:first-child {
            border-top-left-radius: 0.5em;
          }
          &:last-child {
            border-top-right-radius: 0.5em;
          }
        }
      }
    }
  }
}
