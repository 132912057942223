.YearFilterGraph {
  position: relative;
  display: inline-block;
  .reset {
    position: absolute;
    top: 0;
    right: 0;

  }

  .yearFilterGraphTooltip {


  }
  .graphContainer {
    position: relative;
  }
  line {
    transition: opacity 0.3s ease-in-out;
  }

  svg {
    overflow: visible;
  }
}
