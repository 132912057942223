.App {
  position: relative;

  * {
    box-sizing: border-box;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
      padding: 0;
    }
  }

  .container {
    position: relative;
  }

  .intro {
    position: relative;
    top: 0;
    left: 0;
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    .highlight {
      color: #cc0000;
    }
    .intro__text {
      display: flex;

      flex-direction: row;
      align-items: center;
      justify-content: center;
      pointer-events: all;
      text-align: center;
      pointer-events: none;
      &:last-child {
        pointer-events: none;
      }
      .textWrapper {
        pointer-events: all;
        padding: 1em;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 1em;
        max-width: 40em;
        font-size: 1.125em;
        line-height: 1.25em;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
  }
  .exploreButton {
    position: fixed;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    button {
      font-size: 1.125em;
      cursor: pointer;
    }
    text-align: center;
    .scrollToContinue {
      font-size: 0.875em;
      margin-top: 0.5em;
      opacity: 0.6;
      transition: 0.3s ease-in-out opacity;
      &.hideScrollToContinue {
        opacity: 0;
      }
    }
  }

  &.showPreview .exploreButton {
    display: none;
  }

  .resetMap {
    position: absolute;
    top: 4em;
    right: 20px;
  }

  .tooltip {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    border-radius: 0.5em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    pointer-events: none;
    transition: 0.3s ease-in-out opacity;
    .tooltipHeader {
      background-color: #ebebe6;
      padding: 0.5em;
      border-top-right-radius: 0.5em;
      border-top-left-radius: 0.5em;
    }
    .tooltip__title {
      font-weight: bold;
      font-size: 1.125em;
      margin-top: 0.25em;
    }
    .instruction {
      font-size: 0.875em;
    }
    .tooltipContent {
      border-bottom-right-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
      padding: 0.5em;
    }
  }
  .instruction {
    color: #cc0000;
    font-size: 0.875em;
  }

  .table-instructions {
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    margin-top: 3em;
    z-index: 97;
    .table-instructions__text {
      border: #cc0000 1px solid;
      color: #cc0000;
      padding: 2em;
      border-radius: 0.5em;
      pointer-events: none;
      position: relative;
      background-color: rgba(255, 255, 255, 0.9);
      display: inline-block;
      width: 26em;
      font-weight: bold;
      font-size: 1.25em;
      text-align: center;

      position: absolute;
      top: 8em;
      left: 50%;
      transform: translateX(-50%);

      .close {
        position: absolute;
        top: 0;
        right: 1rem;
        color: #000;
        font-size: 1.5em;
        cursor: pointer;
        pointer-events: all;
      }
    }
  }
}
